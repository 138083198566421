<template>
    <v-combobox
      color="primary"
      variant="outlined"
      bg-color="white"
      density="compact"
      class="ml-5 mt-5"
      :label="$store.getters.translate('tags')"
      v-model="new_value"
      :items="tags"
      :disabled="isDisabled"
      :menu="false"
      menu-icon=""
      chips
      closable-chips
      clearable
      :multiple="!single"
      item-value="name.en"
      item-title="name.en"
      :return-object="false"
      append-inner-icon="mdi-tag"
      @click:append-inner="getTags();$refs['tags_' + modal_name].open();"/>
    <BaseDraggableModal
        :name="'tags_' + modal_name"
        max-height="80vh"
        width="70vw"
        :fullscreen="$vuetify.display.xsOnly"
        :ref="'tags_' + modal_name">
      <v-card :loading="loading" :disabled="loading">
        <BaseModal>
          <template #header>
            <v-toolbar class="draggable_selector" color="primary" dense flat>
              <v-toolbar-title>{{ page.title }}</v-toolbar-title>
              <v-spacer/>
              <v-btn size="small" @click="$refs['tags_' + modal_name].close()">
                <v-icon icon="mdi-close" size="x-large"/>
              </v-btn>
            </v-toolbar>
          </template>
          <template #content>
              <v-card-text class="ml-5 mr-5">
                <v-data-table
                    :headers="headers"
                    :items="tags"
                    :loading="loading"
                    v-bind="footer_props"
                    :show-select="false">
                  <template v-slot:item.updated_at="{ item }">{{ moment(item.updated_at).format("DD-MM-YYYY hh:mm") }}</template>
                  <template v-slot:item.actions="{ item }">
                    <v-icon v-if="canDelete" @click="archive(item.id)" color="red" size="small" icon="mdi-delete"/>
                  </template>
                </v-data-table>
              </v-card-text>
          </template>
          <template #footer>
           <v-footer>
              <v-text-field
                color="primary"
                variant="underlined"
                :disabled="!canCreate"
                append-inner-icon="mdi-plus"
                @click:append-inner="save()"
                v-model="record.name"
                :label="$store.getters.translate('create')"
                single-line/>
            </v-footer>
          </template>
        </BaseModal>
      </v-card>
    </BaseDraggableModal>
</template>

<script>
import BaseModal from "./BaseModal";
import BaseDraggableModal from "./BaseDraggableModal";
import helpFunctions from "../../plugins/helpFunctions";
import {useAbility} from "@casl/vue";

export default {
  props: ["modelValue", "modal_name", "single", "available_tags", "isDisabled"],
  components: {
    BaseModal,
    BaseDraggableModal,
  },
  setup: function() {
    const { can } = useAbility();
    return {
      can
    }
  },
  data() {
    return {
      page: helpFunctions.pages.tags,
      new_value: [],
      tags: [],
      loading: false,
      record: {},
      headers: [
        {
          title: this.$store.getters.translate("name"),
          align: "start",
          sortable: true,
          value: "name.en",
        },
        { title: this.$store.getters.translate("updated"), value: "updated_at" },
        {
          title: this.$store.getters.translate("actions"),
          sortable: false,
          align: "end",
          value: "actions",
        },
      ],
      footer_props: helpFunctions.footer_props,
    };
  },
  created() {
    this.new_value = this.modelValue;
    this.getTags();
  },
  methods: {
    ...helpFunctions.modal_functions,
    async save() {
      if(!this.loading) {
        let usual_fields_required = [{name: 'name', mandatory: true}];
        if (helpFunctions.modelFieldsFilled(this.$toast, this.record, usual_fields_required)) {
          this.loading = true;
          let id = await helpFunctions.createModel(this.$toast, this.page.name, this.record);
          if(id) {
            this.record = {};
            this.$refs['tags_' + this.modal_name].close();
            await this.getTags();
          }
          this.loading = false;
        }
      }
    },
    async getTags() {
      this.loading = true;
      this.$http
        .post(this.$store.getters.appUrl + "v2/additional-models", { model: "Tag" })
        .then((response) => {
          this.loading = false;
          this.tags = response.data;
          this.$emit("tags", this.tags);
        })
        .catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toast.error(error.message);
          }
          this.loading = false;
        });
    },
    async archive(id) {
      this.$confirm(this.$store.getters.translate("delete_confirmation")).then(async (res) => {
        if (res) {
          this.loading = true;
          if (await helpFunctions.deleteModel(this.$toast, this.page.name, id)) {
            await this.getTags();
          }
          this.loading = false;
        }
      });
    },
  },
  computed: {
    canCreate() {
      return this.can("create", this.page.model.toLowerCase());
    },
    canDelete() {
      return this.can("delete", this.page.model.toLowerCase());
    },
  },
  watch: {
    new_value: {
      handler() {
        this.$emit("change", this.new_value);
      },
    },
    modelValue: {
      handler() {
        this.new_value = this.modelValue;
      },
    },
    available_tags: {
      handler() {
        if(this.available_tags.length > 0) {
          this.tags = this.available_tags;
        }
        else {
          this.getTags();
        }
      },
    },
  },
};
</script>