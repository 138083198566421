<template>
  <BaseDraggableModal
      name="article_preview"
      max-height="80vh"
      width="100vw"
      :fullscreen="true"
      ref="article_preview">
    <v-card :loading="loading" :disabled="loading" height="100%" width="100%">
      <BaseModal>
        <template #header>
          <v-toolbar class="draggable_selector" color="primary" dense flat>
            <v-btn size="small" @click="closeModal('article_preview')">
              <v-icon icon="mdi-close" size="x-large"/>
            </v-btn>
            <v-toolbar-title>{{ record.tag_number }}</v-toolbar-title>
            <v-spacer/>
            <v-btn size="small" @click="editArticle()">
              <v-icon icon="mdi-pencil" size="x-large"/>
            </v-btn>
          </v-toolbar>
        </template>
        <template #content>
            <v-card-text class="ma-5" style="height: 98vh">
              <v-row>
                <v-col>
                  <v-row>
                    <v-col>
                      <v-layout align-center justify-center column fill-height>
                        <v-img v-if="image" :src="image" class="ma-auto article-img"/>
                        <v-img v-else src="../../../assets/images/new-logo.png" class="ma-auto article-img"/>
                      </v-layout>
                    </v-col>
                  </v-row>
                  <v-divider horizontal class="mt-5 mb-5"/>
                  <v-row>
                    <v-col>{{ $store.getters.translate("last_inspection").toUpperCase() }}:</v-col>
                  </v-row>
                  <template v-if="last_inspection">
                    <v-row>
                      <v-col style="font-weight: bold">{{ $store.getters.translate("gen_project_number") }}:</v-col>
                      <v-col><a @click="editInspection(last_inspection)">{{ last_inspection.gen_project_number }}</a></v-col>
                    </v-row>
                    <v-row>
                      <v-col style="font-weight: bold">{{ $store.getters.translate("gen_inspection_period") }}:</v-col>
                      <v-col>{{ last_inspection.gen_inspection_period }}</v-col>
                    </v-row>
                  </template>
                  <v-row v-else>
                    <v-col>{{ $store.getters.translate("nothing_found") }}</v-col>
                  </v-row>
                  <v-divider horizontal class="mt-5 mb-5"/>
                  <v-row>
                    <v-col>
                      <base-log-field class="mt-5" :model_type="page.model" :model_id="record.id"/>
                    </v-col>
                  </v-row>
                </v-col>
                <v-divider vertical class="ml-5 mr-5"/>
                <v-col cols="5">
                  <v-row>
                    <v-col>{{ $store.getters.translate("tag_number").toUpperCase() }}:</v-col>
                    <v-col>{{ record.tag_number }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col>{{ $store.getters.translate("brand").toUpperCase() }}:</v-col>
                    <v-col v-if="record.brand">{{ record.brand.name }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col>{{ $store.getters.translate("type").toUpperCase() }}:</v-col>
                    <v-col>{{ record.type }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col>{{ $store.getters.translate("model").toUpperCase() }}:</v-col>
                    <v-col>{{ record.model }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col>{{ $store.getters.translate("model_details").toUpperCase() }}:</v-col>
                    <v-col>{{ record.model_details }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col>{{ $store.getters.translate("serial_number").toUpperCase() }}:</v-col>
                    <v-col>{{ record.serial_number }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col>{{ $store.getters.translate("location").toUpperCase() }}:</v-col>
                    <v-col v-if="record.location">{{ record.location.name }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col>{{ $store.getters.translate("room").toUpperCase() }}:</v-col>
                    <v-col v-if="record.room">{{ record.room.name }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col>{{ $store.getters.translate("application").toUpperCase() }}:</v-col>
                    <v-col v-if="record.application">{{ record.application.name }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col>{{ $store.getters.translate("markering").toUpperCase() }}:</v-col>
                    <v-col v-if="record.markering">{{ record.markering.name }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col>{{ $store.getters.translate("iptype").toUpperCase() }}:</v-col>
                    <v-col v-if="record.ip_type">{{ record.ip_type.name }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col>{{ $store.getters.translate("equipmenttype").toUpperCase() }}:</v-col>
                    <v-col v-if="record.equipment_type">{{ $store.getters.translate(record.equipment_type.name) }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col>{{ $store.getters.translate("inspection_institute_name").toUpperCase() }}:</v-col>
                    <v-col v-if="record.inspection_institute">{{ record.inspection_institute.full_name }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col>{{ $store.getters.translate("inspection_certificate_name").toUpperCase() }}:</v-col>
                    <v-col v-if="record.inspection_certificate">{{ record.inspection_certificate.full_name }}</v-col>
                  </v-row>
                  <v-row v-if="record.inspection_certificate && record.inspection_certificate.files && record.inspection_certificate.files.length > 0">
                    <v-col>
                      <div>{{ $store.getters.translate("certificate_files").toUpperCase() }}:</div>
                      <base-row
                          class="mt-3"
                          v-for="(file, index) in record.inspection_certificate.files"
                          :key="index"
                          :label="$store.getters.translate('file')"
                          :modelValue="file.name"
                          @click="downloadFile(file)"
                          :link="true"
                          :hide_copy="true"
                          :deletable="false"/>
                    </v-col>
                  </v-row>
                </v-col>
                <v-divider vertical class="ml-5 mr-5"/>
                <v-col>
                  <v-row>
                    <v-col>{{ $store.getters.translate("root_asset_name").toUpperCase() }}:</v-col>
                    <v-col v-if="record.rootasset"><a @click="goToAsset(record.rootasset.id)">{{ $store.getters.translate(record.rootasset.name) }}</a></v-col>
                  </v-row>
                  <v-divider horizontal class="mt-5 mb-5"/>
                  <v-row>
                    <v-col>{{ $store.getters.translate("inspections").toUpperCase() }}:</v-col>
                  </v-row>
                  <template v-if="inspections.length > 0" v-for="inspection in inspections">
                    <v-row>
                      <v-col style="font-weight: bold">{{ $store.getters.translate("gen_project_number") }}:</v-col>
                      <v-col><a @click="editInspection(inspection)">{{ inspection.gen_project_number }}</a></v-col>
                    </v-row>
                    <v-row>
                      <v-col style="font-weight: bold">{{ $store.getters.translate("gen_inspection_period") }}:</v-col>
                      <v-col>{{ inspection.gen_inspection_period }}</v-col>
                    </v-row>
                    <v-divider horizontal class="mt-5 mb-5"/>
                  </template>
                  <v-row v-else>
                    <v-col>{{ $store.getters.translate("nothing_found") }}</v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-expansion-panels>
                    <base-table-includable ref="article_items_table" :page="page" :parent_module_id="record.id" relation_name="articleitems" :preview="true"/>
                  </v-expansion-panels>
                </v-col>
              </v-row>
            </v-card-text>
        </template>
      </BaseModal>
    </v-card>
  </BaseDraggableModal>
</template>

<script>
import helpFunctions from "../../plugins/helpFunctions";
import BaseModal from "./BaseModal";
import BaseDraggableModal from "./BaseDraggableModal";
import BaseTableIncludable from "./BaseTableIncludableData.vue";
import moment from "moment";
import BaseLogField from "./BaseLogField.vue";

export default {
  components: {
    BaseLogField,
    BaseTableIncludable,
    BaseModal,
    BaseDraggableModal,
  },
  data() {
    return {
      page: helpFunctions.pages.articles,
      loading: false,
      record: {},
      image: null,
      inspections: [],
      last_inspection: null,
    };
  },
  methods: {
    ...helpFunctions.modal_functions,
    async load(id) {
      this.record = {};
      this.image = null;
      this.openModal('article_preview');
      this.loading = true;
      this.record = await helpFunctions.loadModel(this.$toast, this.page.name, id);
      this.updateIncludableTables();
      if(this.record.files && this.record.files.length > 0) {
        this.$http
            .get(this.$store.getters.appUrl + "v2/files/" + this.record.files[0].id, { responseType: "blob" })
            .then((response) => {
              const blob = new Blob([response.data], {type: this.record.files[0].content_type});
              this.image = URL.createObjectURL(blob);
            }).catch((error) => {
              if (this.$store.getters.isLoggedIn) {
                this.$toast.error(error.message);
              }
            });
      }
      this.inspections = [];
      if(this.record.inspectionitems && this.record.inspectionitems.length > 0) {
        this.record.inspectionitems.forEach((inspectionitem) => {
          if(inspectionitem.inspection) {
            let already_exists = this.inspections.filter(inspection => inspection.id === inspectionitem.inspection.id);
            if(already_exists.length === 0) {
              this.inspections.push(inspectionitem.inspection);
              if(this.last_inspection) {
                let last_inspection_date = "01-" + this.last_inspection.gen_inspection_period;
                let inspection_date = "01-" + inspectionitem.inspection.gen_inspection_period;
                if(moment(last_inspection_date) < moment(inspection_date)) {
                  this.last_inspection = inspectionitem.inspection;
                }
              }
              else {
                this.last_inspection = inspectionitem.inspection;
              }
            }
          }
        });
      }
      this.loading = false;
    },
    updateIncludableTables() {
      this.$refs.article_items_table.records = this.record.articleitems;
      this.$refs.article_items_table.selected = [];
    },
    downloadFile(file) {
      this.loading = true;
      this.$http
          .get(this.$store.getters.appUrl + "v2/files/" + file.id, { responseType: "blob" })
          .then((response) => {
            this.loading = false;
            const blob = new Blob([response.data], { type: file.content_type });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = file.name;
            link.click();
            URL.revokeObjectURL(link.href);
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toast.error(error.message);
            }
            this.loading = false;
          });
    },
    editArticle() {
      this.$router.push("/articles/" + this.record.id);
    },
    editInspection(item) {
      this.$router.push("/inspections/" + item.id);
    },
    goToAsset(asset_id) {
      this.$router.push({name: "Assets", params: { asset_id: asset_id }});
    },
  },
};
</script>

<style scoped>
.article-img {
  max-height: 200px;
  max-width: 200px;
}
</style>