<template>
  <v-form ref="form" v-model="valid">
    <v-row v-for="(address, index) in updated_addresses" :key="index">
      <v-col cols="12" sm="4" md="2">
        <v-text-field
            color="primary"
            variant="underlined"
            :label="$store.getters.translate('number')"
            v-model="address.number"/>
      </v-col>
      <v-col cols="12" sm="8" md="10">
        <base-row
            :label="$store.getters.translate('address')"
            :modelValue="address.formatted_address"
            :deletable="!isDisabled"
            @delete="deleteAddress(address)"
            prepend-icon="mdi-google-maps"
            :hide-copy="true"/>
      </v-col>
    </v-row>
    <v-text-field
        color="primary"
        variant="underlined"
        ref="autocomplete"
        id="inputSearch"
        append-icon="mdi-map-marker"
        :disabled="isDisabled"
        :placeholder="$store.getters.translate('type_address')"
        v-model="searchInput"/>
  </v-form>
</template>

<script>
import { Loader } from '@googlemaps/js-api-loader';

export default {
  props: ["modelValue", "is_submit", "isDisabled"],
  data() {
    return {
      updated_addresses: [],
      valid: true,
      searchInput: '',
    };
  },
  mounted() {
    this.updated_addresses = this.modelValue ? this.modelValue : [];
    this.$nextTick(this.initGooglePlacesAutocomplete);
  },
  methods: {
    initGooglePlacesAutocomplete() {
      const loader = new Loader({
        apiKey: "AIzaSyDETzSbmipza8r7Ux2aqq5jNYwt7gndx_4",
        libraries: ["places"],
      });
      loader.load().then(() => {
        const inputElement = this.$refs.autocomplete?.$el.querySelector('input');
        const autocomplete = new google.maps.places.Autocomplete(inputElement, {
          types: ['address'],
          componentRestrictions: { country: "nl" },
        });

        autocomplete.addListener("place_changed", () => {
          const place = autocomplete.getPlace();
          if (!place.geometry) return;
          this.handlePlaceSelect(place);
        });
      });
    },
    handlePlaceSelect(place) {
      const address = {
        street: this.getAddressComponent(place.address_components, 'route'),
        zipcode: this.getAddressComponent(place.address_components, 'postal_code'),
        number: this.getAddressComponent(place.address_components, 'street_number'),
        city: this.getAddressComponent(place.address_components, 'locality'),
        municipality: this.getAddressComponent(place.address_components, 'administrative_area_level_2'),
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
        country: 'NL',
        formatted_address: place.formatted_address,
        name: place.formatted_address,
        type: "visit",
      };
      if (!this.updated_addresses.find((addr) => addr.formatted_address === address.formatted_address)) {
        this.updated_addresses.push(address);
        this.searchInput = '';
        this.$emit("update", this.updated_addresses);
      }
    },
    deleteAddress(row) {
      this.$confirm(this.$store.getters.translate("delete_confirmation")).then((res) => {
        if (res) {
          this.updated_addresses = this.updated_addresses.filter((address) => address.id !== row.id || address.formatted_address !== row.formatted_address);
          if (row.id) {
            this.$http
                .delete(this.$store.getters.appUrl + "v2/addresses/" + row.id)
                .then(() => {
                  this.$toast.success(this.$store.getters.translate("success"));
                  this.$emit("update", this.updated_addresses);
                }).catch((error) => {
                  if (this.$store.getters.isLoggedIn) {
                    this.$toast.error(error.message);
                  }
                });
          }
        }
      });
    },
    getAddressComponent(components, type) {
      return components.find(component => component.types.includes(type))?.long_name || '';
    },
  },
  watch: {
    modelValue: {
      handler() {
        this.updated_addresses = this.modelValue ? this.modelValue : [];
      },
    },
    is_submit:function() {
      this.searchInput = '';
    }
  },
}
</script>
<style scoped>
.pac-container {
  z-index: 2500 !important;
}
</style>