import { createRouter, createWebHistory } from 'vue-router'

import store from './store'
const scrollBehavior = (to, from, savedPosition) => {
    if (to.hash) {
        const element = document.querySelector(to.hash);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    } else if (savedPosition) {
        window.scrollTo({ top: savedPosition.y, behavior: 'smooth' });
    }
    return new Promise(resolve => {
        setTimeout(() => {
            if (to.hash) {
                resolve({ el: to.hash, behavior: 'smooth' });
            } else {
                resolve(savedPosition ? { x: 0, y: savedPosition.y } : { x: 0, y: 0 });
            }
        }, 500); // Adjust timing as necessary for page load or transitions
    });
}
const router = createRouter({
    "history": createWebHistory(),
    base: process.env.BASE_URL,
    scrollBehavior,
    // This is for the scroll top when click on any router link
    routes: [
        {
            publicPath: '',
            historyApiFallback: true,
            path: '',
            component: () => import('./layouts/full-layout/Layout'),
            meta: {
                requiresAuth: true
            },
            children: [
                {
                    name: '403',
                    path: '/403',
                    component: () => import('./views/errors/403'),
                },
                {
                    name: 'Dashboard',
                    path: '',
                    component: () => import('./views/Dashboard'),
                },
                {
                    name: 'Ambienttemperatures',
                    path: 'ambienttemperatures',
                    meta: { model: 'ambienttemperature', action: 'read' },
                    component: () => import('./views/ambienttemperatures/List'),
                },
                {
                    name: 'Applications',
                    path: 'applications',
                    meta: { model: 'application', action: 'read' },
                    component: () => import('./views/applications/List'),
                },
                {
                    name: 'Articles',
                    path: 'articles',
                    meta: { name: 'article', action: 'read' },
                    component: () => import('./views/articles/List'),
                },
                {
                    name: 'Assets',
                    path: 'platforms/:asset_id?',
                    meta: { name: 'asset', action: 'read' },
                    component: () => import('./views/assets/List'),
                },
                {
                    name: 'Articles edit',
                    path: 'articles/:id',
                    meta: { model: 'article' },
                    component: () => import('./views/articles/Edit'),
                },
                {
                    name: 'Brands',
                    path: 'brands',
                    meta: { model: 'brand', action: 'read' },
                    component: () => import('./views/brands/List'),
                },
                {
                    name: 'Classifications',
                    path: 'classifications',
                    meta: { model: 'classification', action: 'read' },
                    component: () => import('./views/classifications/List'),
                },
                {
                    name: 'Characteristics',
                    path: 'characteristics',
                    meta: { model: 'characteristic', action: 'read' },
                    component: () => import('./views/characteristics/List'),
                },
                {
                    name: 'Climate',
                    path: 'climate',
                    meta: { model: 'climate', action: 'read' },
                    component: () => import('./views/climate/List'),
                },
                {
                    name: 'Companies',
                    path: 'companies',
                    meta: { model: 'company', action: 'read' },
                    component: () => import('./views/companies/List'),
                },
                {
                    name: 'Companies edit',
                    path: 'companies/:id',
                    meta: { model: 'company' },
                    component: () => import('./views/companies/Edit'),
                },
                {
                    name: 'Contact',
                    path: 'contacts',
                    meta: { model: 'contact', action: 'read' },
                    component: () => import('./views/contacts/List'),
                },
                {
                    name: 'Contact edit',
                    path: 'contacts/:id',
                    meta: { model: 'contact' },
                    component: () => import('./views/contacts/Edit'),
                },
                {
                    name: 'Conformitymarks',
                    path: 'conformitymarks',
                    meta: { model: 'conformitymark', action: 'read' },
                    component: () => import('./views/conformitymarks/List'),
                },
                {
                    name: 'Damagechances',
                    path: 'damagechances',
                    meta: { model: 'damagechance', action: 'read' },
                    component: () => import('./views/damagechances/List'),
                },
                {
                    name: 'Defects',
                    path: 'defects',
                    meta: { model: 'defect', action: 'read' },
                    component: () => import('./views/defects/List'),
                },
                {
                    name: 'Defect edit',
                    path: 'defects/:id',
                    meta: { model: 'defect' },
                    component: () => import('./views/defects/Edit'),
                },
                {
                    name: 'Distributionpanels',
                    path: 'distributionpanels',
                    meta: { model: 'distributionpanel', action: 'read' },
                    component: () => import('./views/distributionpanels/List'),
                },
                {
                    name: 'Distributiondevicefunctions',
                    path: 'distributiondevicefunctions',
                    meta: { model: 'distributiondevicefunction', action: 'read' },
                    component: () => import('./views/distributiondevicefunctions/List'),
                },
                {
                    name: 'Documents',
                    path: 'documents',
                    meta: { name: 'document', action: 'read' },
                    component: () => import('./views/documents/List'),
                },
                {
                    name: 'Documentation',
                    path: 'documentation',
                    component: () => import('./views/documentation/Index'),
                },
                {
                    name: 'Employees',
                    path: 'employees',
                    meta: { model: 'employee', action: 'read' },
                    component: () => import('./views/employees/List'),
                },
                {
                    name: 'Employees edit',
                    path: 'employees/:id',
                    meta: { model: 'employee' },
                    component: () => import('./views/employees/Edit'),
                },
                {
                    name: 'Emergencies',
                    path: 'emergencies',
                    meta: { model: 'emergency', action: 'read' },
                    component: () => import('./views/emergencies/List'),
                },
                {
                    name: 'Environments',
                    path: 'environments',
                    meta: { model: 'environment', action: 'read' },
                    component: () => import('./views/environments/List'),
                },
                {
                    name: 'Employeeexpertise',
                    path: 'employeeexpertise',
                    meta: { model: 'employeeexpertise', action: 'read' },
                    component: () => import('./views/employeeexpertise/List'),
                },
                {
                    name: 'Equipment',
                    path: 'equipment',
                    meta: { model: 'equipment', action: 'read' },
                    component: () => import('./views/equipment/List'),
                },
                {
                    name: 'Equipment edit',
                    path: 'equipment/:id',
                    meta: { model: 'equipment' },
                    component: () => import('./views/equipment/Edit'),
                },
                {
                    name: 'Equipmenttypes',
                    path: 'equipmenttypes',
                    meta: { model: 'equipmenttype', action: 'read' },
                    component: () => import('./views/equipmenttypes/List'),
                },
                {
                    name: 'Equipmentgroups',
                    path: 'equipmentgroups',
                    meta: { model: 'equipmentgroup', action: 'read' },
                    component: () => import('./views/equipmentgroups/List'),
                },
                {
                    name: 'Equipmentprotectionlevels',
                    path: 'equipmentprotectionlevels',
                    meta: { model: 'equipmentprotectionlevel', action: 'read' },
                    component: () => import('./views/equipmentprotectionlevels/List'),
                },
                {
                    name: 'Equipmentcategories',
                    path: 'equipmentcategories',
                    meta: { model: 'equipmentcategory', action: 'read' },
                    component: () => import('./views/equipmentcategories/List'),
                },
                {
                    name: 'Explosiveatmospheres',
                    path: 'explosiveatmospheres',
                    meta: { model: 'explosiveatmosphere', action: 'read' },
                    component: () => import('./views/explosiveatmospheres/List'),
                },
                {
                    name: 'Field sets',
                    path: 'fieldsets',
                    meta: { model: 'fieldset', action: 'read' },
                    component: () => import('./views/fieldsets/List'),
                },
                {
                    name: 'Field sets edit',
                    path: 'fieldsets/:id',
                    meta: { model: 'fieldset' },
                    component: () => import('./views/fieldsets/Edit'),
                },
                {
                    name: 'Fields',
                    path: 'fields',
                    meta: { model: 'field', action: 'read' },
                    component: () => import('./views/fields/List'),
                },
                {
                    name: 'Forms',
                    path: 'forms',
                    meta: { model: 'form', action: 'read' },
                    component: () => import('./views/forms/List'),
                },
                {
                    name: 'Form edit',
                    path: 'forms/:id',
                    meta: { model: 'form' },
                    component: () => import('./views/forms/Edit'),
                },
                {
                    name: 'Gasgroups',
                    path: 'gasgroups',
                    meta: { model: 'gasgroup', action: 'read' },
                    component: () => import('./views/gasgroups/List'),
                },
                {
                    name: 'Import assets',
                    path: 'importassets',
                    component: () => import('./views/importassets/Index'),
                },
                {
                    name: 'Intensity',
                    path: 'intensity',
                    meta: { model: 'intensity', action: 'read' },
                    component: () => import('./views/intensity/List'),
                },
                {
                    name: 'Inspections',
                    path: 'inspections',
                    meta: { name: 'inspection', action: 'read' },
                    component: () => import('./views/inspections/List'),
                },
                {
                    name: 'Inspections edit',
                    path: 'inspections/:id',
                    meta: { model: 'inspection' },
                    component: () => import('./views/inspections/Edit'),
                },
                {
                    name: 'Inspectionclasses',
                    path: 'inspectionclasses',
                    meta: { model: 'inspectionclass', action: 'read' },
                    component: () => import('./views/inspectionclasses/List'),
                },
                {
                    name: 'Inspectioninstitutes',
                    path: 'inspectioninstitutes',
                    meta: { model: 'inspectioninstitute', action: 'read' },
                    component: () => import('./views/inspectioninstitutes/List'),
                },
                {
                    name: 'Inspectioninstitutecertifications',
                    path: 'inspectioninstitutecertifications',
                    meta: { model: 'inspectioninstitutecertification', action: 'read' },
                    component: () => import('./views/inspectioninstitutecertifications/List'),
                },
                {
                    name: 'Inspectioncodes',
                    path: 'inspectioncodes',
                    meta: { model: 'inspectioncode', action: 'read' },
                    component: () => import('./views/inspectioncodes/List'),
                },
                {
                    name: 'Inspectioncertificates',
                    path: 'inspectioncertificates',
                    meta: { model: 'inspectioncertificate', action: 'read' },
                    component: () => import('./views/inspectioncertificates/List'),
                },
                {
                    name: 'Inspectioncertificates edit',
                    path: 'inspectioncertificates/:id',
                    meta: { model: 'inspectioncertificate' },
                    component: () => import('./views/inspectioncertificates/Edit'),
                },
                {
                    name: 'Iptypes',
                    path: 'iptypes',
                    meta: { model: 'iptype', action: 'read' },
                    component: () => import('./views/iptypes/List'),
                },
                {
                    name: 'Ipindications',
                    path: 'ipindications',
                    meta: { model: 'ipindication', action: 'read' },
                    component: () => import('./views/ipindications/List'),
                },
                {
                    name: 'Inspectiondelays',
                    path: 'inspectiondelays',
                    meta: { model: 'inspectiondelay', action: 'read' },
                    component: () => import('./views/inspectiondelays/List'),
                },
                {
                    name: 'Impactloads',
                    path: 'impactloads',
                    meta: { model: 'impactload', action: 'read' },
                    component: () => import('./views/impactloads/List'),
                },
                {
                    name: 'Linevoltage',
                    path: 'linevoltage',
                    meta: { model: 'linevoltage', action: 'read' },
                    component: () => import('./views/linevoltage/List'),
                },
                {
                    name: 'Locations',
                    path: 'locations',
                    meta: { model: 'location', action: 'read' },
                    component: () => import('./views/locations/List'),
                },
                {
                    name: 'Markering',
                    path: 'markering',
                    meta: { model: 'markering', action: 'read' },
                    component: () => import('./views/markering/List'),
                },
                {
                    name: 'Materials',
                    path: 'materials',
                    meta: { model: 'material', action: 'read' },
                    component: () => import('./views/materials/List'),
                },
                {
                    name: 'Notes',
                    path: 'notes',
                    meta: { model: 'note', action: 'read' },
                    component: () => import('./views/notes/List'),
                },
                {
                    name: 'Planner',
                    path: 'planning',
                    component: () => import('./views/planner/List'),
                },
                {
                    name: 'Projects',
                    path: 'projects',
                    meta: { name: 'project', action: 'read' },
                    component: () => import('./views/projects/List'),
                },
                {
                    name: 'Project edit',
                    path: 'projects/:id',
                    meta: { model: 'project' },
                    component: () => import('./views/projects/Edit'),
                },
                {
                    name: 'Punchlists',
                    path: 'punchlists',
                    meta: { name: 'punchlist', action: 'read' },
                    component: () => import('./views/punchlists/List'),
                },
                {
                    name: 'Punchlist edit',
                    path: 'punchlists/:id',
                    meta: { model: 'punchlist' },
                    component: () => import('./views/punchlists/Edit'),
                },
                {
                    name: 'Profile',
                    path: 'profile',
                    component: () => import('./views/Profile'),
                },
                {
                    name: 'Powersystems',
                    path: 'powersystems',
                    meta: { model: 'powersystem', action: 'read' },
                    component: () => import('./views/powersystems/List'),
                },
                {
                    name: 'Protectionkinds',
                    path: 'protectionkinds',
                    meta: { model: 'protectionkind', action: 'read' },
                    component: () => import('./views/protectionkinds/List'),
                },
                {
                    name: 'Protections',
                    path: 'protections',
                    meta: { model: 'protection', action: 'read' },
                    component: () => import('./views/protections/List'),
                },
                {
                    name: 'Protectiontypes',
                    path: 'protectiontypes',
                    meta: { model: 'protectiontype', action: 'read' },
                    component: () => import('./views/protectiontypes/List'),
                },
                {
                    name: 'Protectionmethods',
                    path: 'protectionmethods',
                    meta: { model: 'protectionmethod', action: 'read' },
                    component: () => import('./views/protectionmethods/List'),
                },
                {
                    name: 'Questionnaires',
                    path: 'questionnaires',
                    meta: { model: 'questionnaire', action: 'read' },
                    component: () => import('./views/questionnaires/List'),
                },
                {
                    name: 'Questionnaires edit',
                    path: 'questionnaires/:id',
                    meta: { model: 'questionnaire' },
                    component: () => import('./views/questionnaires/Edit'),
                },
                {
                    name: 'Regulations',
                    path: 'regulations',
                    meta: { model: 'regulation', action: 'read' },
                    component: () => import('./views/regulations/List'),
                },
                {
                    name: 'Report sections',
                    path: 'reportsections',
                    meta: { model: 'reportsection', action: 'read' },
                    component: () => import('./views/reportsections/List'),
                },
                {
                    name: 'Report sections edit',
                    path: 'reportsections/:id',
                    meta: { model: 'reportsection' },
                    component: () => import('./views/reportsections/Edit'),
                },
                {
                    name: 'Role',
                    path: 'roles',
                    meta: { model: 'role', action: 'read' },
                    component: () => import('./views/roles/List'),
                },
                {
                    name: 'Role Edit',
                    path: 'roles/:id',
                    meta: { model: 'role' },
                    component: () => import('./views/roles/Edit'),
                },
                {
                    name: 'Rooms',
                    path: 'rooms',
                    meta: { model: 'room', action: 'read' },
                    component: () => import('./views/rooms/List'),
                },
                {
                    name: 'Stof',
                    path: 'stof',
                    meta: { model: 'stof', action: 'read' },
                    component: () => import('./views/stof/List'),
                },
                {
                    name: 'Search',
                    path: 'search',
                    meta: { model: 'search' },
                    component: () => import('./views/search/Index'),
                },
                {
                    name: 'Inspections config',
                    path: 'inspectionsconfig',
                    component: () => import('./views/inspectionsconfig/Index'),
                },
                {
                    name: 'Settings Edit',
                    path: 'settings',
                    meta: { model: 'setting' },
                    component: () => import('./views/settings/Edit'),
                },
                {
                    name: 'Substances',
                    path: 'substances',
                    meta: { model: 'substance', action: 'read' },
                    component: () => import('./views/substances/List'),
                },
                {
                    name: 'Tags',
                    path: 'tags',
                    meta: { model: 'tag', action: 'read' },
                    component: () => import('./views/tags/List'),
                },
                {
                    name: 'Templates',
                    path: 'templates',
                    meta: { model: 'template', action: 'read' },
                    component: () => import('./views/templates/List'),
                },
                {
                    name: 'Templates edit',
                    path: 'templates/:id',
                    meta: { model: 'template' },
                    component: () => import('./views/templates/Edit'),
                },
                {
                    name: 'Temperatureclasses',
                    path: 'temperatureclasses',
                    meta: { model: 'temperatureclass', action: 'read' },
                    component: () => import('./views/temperatureclasses/List'),
                },
                {
                    name: 'Translations',
                    path: 'translations',
                    meta: { model: 'translation', action: 'read' },
                    component: () => import('./views/translations/List'),
                },
                {
                    name: 'Typesandsubtypes',
                    path: 'typesandsubtypes',
                    meta: { model: 'typesandsubtype', action: 'read' },
                    component: () => import('./views/typesandsubtypes/List'),
                },
                {
                    name: 'Usagefrequency',
                    path: 'usagefrequency',
                    meta: { model: 'usagefrequency', action: 'read' },
                    component: () => import('./views/usagefrequency/List'),
                },
                {
                    name: 'Vibrations',
                    path: 'vibrations',
                    meta: { model: 'vibration', action: 'read' },
                    component: () => import('./views/vibrations/List'),
                },
                {
                    name: 'Water',
                    path: 'water',
                    meta: { model: 'water', action: 'read' },
                    component: () => import('./views/water/List'),
                },
                {
                    name: 'Workflow',
                    path: 'workflows',
                    meta: { model: 'workflow', action: 'read' },
                    component: () => import('./views/workflows/List'),
                },
                {
                    name: 'Workflow Edit',
                    path: 'workflows/:id',
                    meta: { model: 'workflow' },
                    component: () => import('./views/workflows/Edit'),
                },
                {
                    name: 'Workequipmentgroups',
                    path: 'workequipmentgroups',
                    meta: { model: 'workequipmentgroup', action: 'read' },
                    component: () => import('./views/workequipmentgroups/List'),
                },
            ]
        },
        {
            name: 'Login',
            path: '/login',
            component: () => import('./views/authentication/Login'),
        },
        {
            name: 'Error',
            path: '/error404',
            component: () => import('./views/Error'),
        },

        {
            path: '/authentication',
            component: () => import('./layouts/blank-layout/Blanklayout'),
        },
        {
            name: 'Complete registration',
            path: '/completeregistration',
            publicPath: '/completeregistration',
            component: () => import('./views/authentication/CompleteRegistration'),
            meta: {
                requiresAuth: false
            },
        },
        {
            name: 'Password reset',
            path: '/resetpassword',
            publicPath: '/resetpassword',
            component: () => import('./views/authentication/PasswordReset'),
            meta: {
                requiresAuth: false
            },
        },
        {
            publicPath: '',
            historyApiFallback: true,
            path: '',
            component: () => import('./layouts/full-layout/Layout'),
            children: [
                {
                    name: '403',
                    path: '/403',
                    component: () => import('./views/errors/403'),
                },
            ]
        },
    ],
})
router.beforeEach((to, from, next) => {
    if(to.path === '/completeregistration' || to.path === '/resetpassword') {
        localStorage.removeItem("path");
    }
    else if(to.path !== '/login') {
        var path = to.path;
        if (to.query.id) {
            path += "?id=" + to.query.id;
        }
        localStorage.setItem("path", path);
    }
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.isLoggedIn) {
            next();
        } else {
            next('/login');
        }

    } else {
        next();
    }
})

router.beforeEach((to, from, next) => {
    if (to.params.asset_id) {
        to.params.asset_id = parseInt(to.params.asset_id, 10) || null;
    }
    next();
})

export default router