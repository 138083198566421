<template>
  <BaseDraggableModal
      name="base_modal_fields"
      max-height="80vh"
      width="70vw"
      :fullscreen="$vuetify.display.xsOnly"
      ref="base_modal_fields">
    <v-card>
      <BaseModal>
        <template #header>
          <v-toolbar class="draggable_selector" color="primary" dense flat>
            <v-btn size="small" @click="reset()">
              <v-icon icon="mdi-close" size="x-large"/>
            </v-btn>
            <v-toolbar-title>{{ page.title }}</v-toolbar-title>
            <v-spacer/>
            <v-btn size="small" @click="save()">
              <v-icon icon="mdi-content-save" size="x-large"/>
            </v-btn>
          </v-toolbar>
        </template>
        <template #content>
          <base-table-fields ref="base_table_fields" :page="page" :current_model="selected_model" :modal="true"/>
        </template>
      </BaseModal>
    </v-card>
  </BaseDraggableModal>
</template>

<script>
import helpFunctions from "../../plugins/helpFunctions";
import BaseModal from "./BaseModal";
import BaseDraggableModal from "./BaseDraggableModal";
import BaseTableFields from "./BaseTableFields";

export default {
  props: ["selected_model"],
  components: {
    BaseTableFields,
    BaseModal,
    BaseDraggableModal
  },
  data() {
    return {
      page: helpFunctions.pages.fields,
    };
  },
  methods: {
    ...helpFunctions.modal_functions,
    async load() {
      this.openModal('base_modal_fields');
    },
    save() {
      this.$refs.base_table_fields.save();
    },
    reset() {
      this.$emit("refresh");
      this.closeModal('base_modal_fields');
    },
  },
};
</script>