<template>
  <BaseDraggableModal
      :name="modal_name"
      max-height="80vh"
      width="70vw"
      :fullscreen="$vuetify.display.xsOnly"
      :ref="modal_name">
    <v-card :loading="loading" :disabled="loading">
      <BaseModal>
        <template #header>
          <v-toolbar class="draggable_selector" color="primary" dense flat>
            <v-btn size="small" @click="reset()">
              <v-icon icon="mdi-close" size="x-large"/>
            </v-btn>
            <v-toolbar-title v-if="label">{{ $store.getters.translate(label) }}</v-toolbar-title>
            <v-toolbar-title v-else>{{ page.title }}</v-toolbar-title>
            <v-spacer/>
            <v-btn :disabled="loading" size="small" @click="save()">
              <v-icon icon="mdi-content-save" size="x-large"/>
            </v-btn>
          </v-toolbar>
        </template>
        <template #content>
            <v-card-text class="ml-5 mr-5">
              <!--USUAL FIELDS-->
              <base-field
                  v-for="field in usual_fields"
                  :key="field.name"
                  :isDisabled="field.name === 'date'"
                  :modelValue="record[field.name]"
                  :field="field"
                  :create="canCreateNewModel(field.name)"
                  @change="usualFieldDataChanged"
                  @create="createNewModel"/>
              <!--CUSTOM FIELDS-->
              <base-field
                  v-if="record.custom_fields"
                  v-for="field in custom_fields"
                  :key="field.name"
                  :modelValue="record.custom_fields[field.name]"
                  :field="field"
                  @change="customFieldDataChanged"/>
              <!--UPLOAD FILES-->
              <v-row v-if="files_required">
                <v-col v-cloak @drop.prevent="addDropFile" @dragover.prevent>
                  <v-file-input
                      v-model="new_files"
                      :multiple="multiple_files"
                      :label="$store.getters.translate('files')"
                      placeholder="Drop file to upload"
                      class="mt-3"
                      color="primary"
                      bg-color="white"
                      show-size/>
                </v-col>
              </v-row>
              <base-addresses v-if="addresses_required" v-model="record.addresses" @update="addressesChanged"/>
              <base-tags v-if="tags_required" :modelValue="record.tags" :modal_name="page.name" @change="tagsChanged"/>
            </v-card-text>
        </template>
      </BaseModal>
    </v-card>
  </BaseDraggableModal>
</template>

<script>
import helpFunctions from "../../plugins/helpFunctions";
import BaseModal from "../../components/commonComponents/BaseModal";
import BaseDraggableModal from "../../components/commonComponents/BaseDraggableModal";
import moment from "moment";
import BaseAddresses from "./BaseAddresses";
import {useAbility} from "@casl/vue";

export default {
  props: ["modal_name", "page", "addresses_required", "files_required", "multiple_files"],
  components: {
    BaseAddresses,
    BaseModal,
    BaseDraggableModal,
  },
  setup() {
    const { can } = new useAbility();
    return { can }
  },
  data() {
    return {
      label: null,
      loading: false,
      record: {},
      usual_fields: [],
      custom_fields: [],
      tags_required: false,
      //APPLICATION VARIABLES. NOT USED FOR OTHER MODELS
      application_equipment_group: null,
      application_equipment_category: null,
      application_explosive_atmosphere: null,
      //MARKERING VARIABLES. NOT USED FOR OTHER MODELS
      regulation: null,
      gas_group: null,
      protection_method: null,
      temperature_class: null,
      equipment_protection_level: null,
      //FILES VARIABLE
      new_files: []
    };
  },
  methods: {
    ...helpFunctions.modal_functions,
    load() {
      if(this.page.model === 'company' || this.page.model === 'project' || this.page.model === 'inspectionitem' ||  this.page.model === 'defect' ||  this.page.model === 'article' ||  this.page.model === 'articleitem') {
        this.record = {
          custom_fields: {},
        };
      }
      else {
        this.record = {};
      }
      this.openModal(this.modal_name);
      this.getFields();
    },
    async save() {
      if(!this.loading) {
        if (helpFunctions.modelFieldsFilled(this.$toast, this.record, this.usual_fields)) {
          this.loading = true;
          if(this.page.model === 'project') {
            if (!this.record.start_date) {
              this.record.start_date = moment(moment.now()).format('DD-MM-YYYY');
            }
            if (!this.record.end_date) {
              this.record.end_date = this.record.start_date;
            }
          }
          else if(this.page.model === 'translation') {
            let new_key = this.$lodash.lowerCase(this.record.key);

            this.record.key = new_key.replaceAll('(', "_").replaceAll(')', "_").replaceAll('[', "_").replaceAll(']', "_")
                .replaceAll('-', "_").replaceAll('/', "_").replaceAll('\\', "_").replace(/ /g, "_");
          }
          let record_id = await helpFunctions.createModel(this.$toast, this.page.name, this.record);
          if(record_id) {
            if(this.new_files.length > 0) {
              await this.uploadFiles(this.new_files, record_id);
            }
            if(this.page.model === 'translation') {
              await this.$store.dispatch("refreshTranslations");
            }
            this.$emit("refresh", record_id);
            this.reset();
          }
          this.loading = false;
        }
      }
    },
    reset() {
      if(this.page.model === 'company' || this.page.model === 'project' || this.page.model === 'inspectionitem' ||  this.page.model === 'defect' ||  this.page.model === 'article' ||  this.page.model === 'articleitem') {
        this.record = {
          custom_fields: {},
        };
      }
      else {
        this.record = {};
      }
      this.new_files = [];
      this.closeModal(this.modal_name);
    },
    createNewModel(field_name) {
      let data = { modal_name: this.modal_name, field_name: field_name };
      this.$emit('createNewModel', data);
    },
    canCreateNewModel(field_name) {
      //will work after removing custom permissions
      return this.modal_name !== 'create_new_record' &&
            ((this.page.model === 'article' && ((field_name === 'application_id' && this.can("create", 'application')) ||
            (field_name === 'markering_id' && this.can("create", 'markering')) ||
            (field_name === 'location_id' && this.can("create", 'location')) ||
            (field_name === 'room_id' && this.can("create", 'room')) ||
            (field_name === 'brand_id' && this.can("create", 'brand')) ||
            (field_name === 'equipment_type_id' && this.can("create", 'equipmenttype')) ||
            (field_name === 'ip_type_id' && this.can("create", 'iptype')) ||
            (field_name === 'inspection_institute_id' && this.can("create", 'inspectioninstitute')) ||
            (field_name === 'inspection_certificate_id' && this.can("create", 'inspectioncertificate')))) ||
            (this.page.model === 'inspectionitem' && ((field_name === 'room_id' && this.can("create", 'room')) ||
            (field_name === 'line_voltage_id' && this.can("create", 'linevoltage')) ||
            (field_name === 'distribution_panel_id' && this.can("create", 'distributionpanel')) ||
            (field_name === 'location_id' && this.can("create", 'location')) ||
            (field_name === 'distribution_device_function_id' && this.can("create", 'distributiondevicefunction')) ||
            (field_name === 'gas_group_id' && this.can("create", 'gasgroup')) ||
            (field_name === 'temperature_class_id' && this.can("create", 'temperaturclass')))) ||
            (this.page.model === 'articleitem' && ((field_name === 'application_id' && this.can("create", 'application')) ||
            (field_name === 'markering_id' && this.can("create", 'markering')) ||
            (field_name === 'line_voltage_id' && this.can("create", 'linevoltage')) ||
            (field_name === 'ip_type_id' && this.can("create", 'iptype')))) ||
            (this.page.model === 'defect' && field_name === 'classification_ids' && this.can("create", 'classification')) ||
            (this.page.model === 'inspectioncertificate' && ((field_name === 'inspection_code_id' && this.can("create", 'inspectioncode')) ||
            (field_name === 'inspection_institute_certification_id' && this.can("create", 'inspectioninstitutecertification')))));
    },
    usualFieldDataChanged(data) {
      if(this.record[data[0]] !== data[1]) {
        this.record[data[0]] = data[1];
        if (data[0] === 'project_id') {
          this.getProjectInfo();
        }
        if (this.page.model === 'application' && (data[0] === 'equipment_group_id' || data[0] === 'equipment_category_id' || data[0] === 'explosive_atmosphere_id')) {
          if (data[0] === 'equipment_group_id') {
            if (data[2]) {
              this.application_equipment_group = data[2].split(" | ")[0]; //data[2] stores selected text value
            }
            else {
              this.application_equipment_group = null;
            }
          } else if (data[0] === 'equipment_category_id') {
            if (data[2]) {
              this.application_equipment_category = data[2].split(" | ")[0]; //data[1] stores selected id (int) value
            }
            else {
              this.application_equipment_category = null;
            }
          } else if (data[0] === 'explosive_atmosphere_id') {
            if (data[2]) {
              this.application_explosive_atmosphere = data[2].split(" | ")[0]; //data[2] stores selected text value
            }
            else {
              this.application_explosive_atmosphere = null;
            }
          }
          this.record.name = "";
          if (this.application_equipment_group) {
            this.record.name = this.application_equipment_group + " ";
          }
          if (this.application_equipment_category) {
            this.record.name += this.application_equipment_category + " ";
          }
          if (this.application_explosive_atmosphere) {
            this.record.name += this.application_explosive_atmosphere;
          }
        }
        else if (this.page.model === 'markering' && (data[0] === 'regulation_id' || data[0] === 'protection_method_id' || data[0] === 'gas_group_id' || data[0] === 'temperature_class_id' || data[0] === 'equipment_protection_level_id')) {
          if (data[0] === 'regulation_id') {
            if (data[2]) {
              this.regulation = data[2].split(" | ")[0]; //data[2] stores selected text value
            } else {
              this.regulation = null;
            }
          } else if (data[0] === 'protection_method_id') {
            if (data[2]) {
              this.protection_method = data[2].split(" | ")[0]; //data[2] stores selected text value
            } else {
              this.protection_method = null;
            }
          } else if (data[0] === 'gas_group_id') {
            if (data[2]) {
              this.gas_group = data[2].split(" | ")[0]; //data[2] stores selected text value
            } else {
              this.gas_group = null;
            }
          } else if (data[0] === 'temperature_class_id') {
            if (data[2]) {
              this.temperature_class = data[2].split(" | ")[0]; //data[2] stores selected text value
              this.record.maximum_surface_temperature = data[2];
            } else {
              this.temperature_class = null;
              this.record.maximum_surface_temperature = '';
            }
          } else if (data[0] === 'equipment_protection_level_id') {
            if (data[2]) {
              this.equipment_protection_level = data[2].split(" | ")[0]; //data[2] stores selected text value
            } else {
              this.equipment_protection_level = null;
            }
          }
          this.record.name = "";
          if (this.regulation) {
            this.record.name = this.regulation + " ";
          }
          if (this.protection_method) {
            this.record.name += this.protection_method + " ";
          }
          if (this.gas_group) {
            this.record.name += this.gas_group + " ";
          }
          if (this.temperature_class) {
            this.record.name += this.temperature_class + " ";
          }
          if (this.equipment_protection_level) {
            this.record.name += this.equipment_protection_level;
          }
        }
      }
    },
    customFieldDataChanged(data) {
      this.record.custom_fields[data[0]] = data[1];
    },
    addressesChanged(updated_addresses) {
      this.record.addresses = updated_addresses;
    },
    tagsChanged(data) {
      this.record.tags = data;
    },
    async getFields() {
      this.loading = true;
      const response = await helpFunctions.getFields(this.page.model, "show_on_creation");
      this.tags_required = response.tags_required;
      this.usual_fields = response.usual_fields;
      this.custom_fields = response.custom_fields;
      this.loading = false;
    },
    addDropFile(e) {
      for (let i = 0; i < e.dataTransfer.files.length; i++) {
        this.new_files.push(e.dataTransfer.files[i]);
      }
    },
    async uploadFiles(files, record_id) {
      this.loading = true;
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append("attachment[]", files[i]);
      }
      formData.append("id", record_id);
      await this.$http.post(this.$store.getters.appUrl + "v2/" + this.page.name + "/uploadfiles", formData, {headers: {"Content-Type": "multipart/form-data"}});
    },
    //PUNCHLIST CUSTOM FUNCTION
    async getProjectInfo() {
      if(this.record.project_id) {
        this.loading = true;
        this.$http
            .post(this.$store.getters.appUrl + "v2/get-one-additional-model", { model: "Project", id: this.record.project_id })
            .then((response) => {
              this.loading = false;
              this.record.name = response.data.name;
              this.record.location = response.data.root_asset_name;
              this.record.requisition = response.data.afe_pef;
              this.record.afe_pef = response.data.afe_pef;
              this.record.tags = response.data.tags;
            })
            .catch((error) => {
              this.$toast.error(error.message);
              this.loading = false;
            });
      }
      else {
        this.record.name = "";
        this.record.location = "";
        this.record.requisition = "";
        this.record.afe_pef = "";
        this.record.tags = [];
      }
    },
  },
};
</script>